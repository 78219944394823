/*
  *
  *
  * EVENT VIEW
  *
  *
  */

.content-center.event-view{
  padding: 1px;
  max-width: 100%;
}

.event-view-event-details-wrapper{
  padding: 18px;
  margin-bottom: 1px;
}

.event-view-event-title{
  font-size: 1.3rem;
  padding: 0 0 4px 0;
}

.event-view-event-datetime{
  color: #e5e5e5;
}

.event-content-markets-wrapper{
  padding: 0 18px;
}

.event-content.market-wrapper{
  background: #FFF;
  margin-bottom: 10px;
}

.event-content.market-wrapper .market-title-container{
  background-color: #CCC;
  padding: 3px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.event-content.market-wrapper .market-name{
  font-size: 0.9rem;
  font-weight: bold;
  color: #000;
}

.market-props-wrapper{
  display: flex;
  justify-content: space-between;
  padding: 3px 10px;
  align-items: center;
}

.market-props-fav-btn-container{
  display: flex;
  align-items: center;
  justify-content: center;
}

.event-content.market-wrapper .market-props-wrapper .market-in-play-prop-container label{
  margin: 0;
}

.market-selections-text{
  background-color: #e5e5e5;
  padding: 2px 10px;
}

.market-selection-row{
  display: flex;
  border-bottom: 1px solid #ccc;
  padding-bottom: 1px;
  margin-bottom: 1px;
}

.market-selection-row .market-selection-name{
  width: 20%;
  padding: 0 10px;
  align-self: center;
}

.market-selection-row .market-selection-bet-container{
  width: 40%;
  display: flex;
  flex-wrap: wrap;
}

.market-selection-row .market-selection-bet-type-back-container{
  justify-content: flex-end;
}

.market-selection-row.market-selection-head-row{
  background-color: #f6f6f6;
}

.market-selection-row.market-selection-head-row .market-selection-bet-container .market-selection-bet-type-title{
  padding: 2px 10px;
}

.market-wrapper{
  position: relative;
}

.market-wrapper .low-liquidity-overlay,
.sports-moneyline_game .low-liquidity-overlay{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.77);
    color: rgba(0, 0, 255, 0.75);
    border: 2px solid #CCC;
    font-weight: bold;
    text-align: center;
    z-index: 2;
    vertical-align: middle;
    display: flex;   
    align-items: center;
    justify-content: center;
}

.market-direct-link-container{
  background-color: #e5e5e5;
  padding: 2px 10px;
  text-align: right;
  border-bottom: 1px solid #CCC;
}

.event-view-event-details-wrapper{
  background-color: #000;
  color: #FFF;
}

.event-content-other-markets-title{
  background-color: #303030;
  padding: 4px 12px;
  color: #FFF;
  font-size: 1rem;
}

.event-content-other-markets-container{
  display: flex;
  gap: 10px;
}

.event-content-other-markets-left,
.event-content-other-markets-right{
  width: 50%;
}

.event-content-other-markets-container .market-selection-row .market-selection-name{
  background-color: #FFF;
  width: 100%;
}

.event-content-other-markets-container .market-selection-row.market-selection-head-row .market-selection-name{
  background-color: #f6f6f6;
}

// horse racing classes
.hrEventLoaderContainer {
  padding: 100px 0;
  margin: 0 auto;
}

.event-view.horse-racing-event-view{
  position: relative;
  
  .markets-list-mobile-tabs-wrapper{
    position: absolute;
    left: 0;
    right: 0;
  }

  .event-content-markets-wrapper{
    margin-top: 80px;
  }
}

.event-cashouts-wrapper {
  padding: 2px 18px;
  margin-bottom: 10px;

  .event-view.horse-racing-event-view & {
    margin-top: 50px;
  }

  .event-cashouts-headline {
    background-color: $theme-bg-color-4;
    color: $theme-text-color-4;
    padding: 4px 10px;
    font-weight: bold;
    font-size: 1rem;
  }

  .event-cashouts-list {
    background-color: $theme-bg-color-3;
    display: flex;
    gap: 10px;
    flex-wrap: wrap;

    .cashout-item-wrapper {
      flex-grow: 1;
    }
  }
}

.event-content-top-market-title{
  background-color: #303030;
  padding: 4px 12px;
  color: #FFF;
  font-size: 1rem;
  margin: 0 18px;
}

@media screen and (max-width: $screen-width-mobile-max-width) {
  
  .event-content-other-markets-container{
      display: block;
  }

  .event-content-other-markets-left, .event-content-other-markets-right{
      width: auto;
  }

  .event-content-markets-wrapper{
      padding-left: 0;
      padding-right: 0;
  }

  .market-selection-row .market-selection-name{
      width: 100%;
  }

  .event-cashouts-wrapper {
    .event-cashouts-list {
      display: block;
    }
  }

  .event-content-top-market-title {
    margin: 0;
  }
}
