// overrides variables set in /src/styles/variables/base.scss
// these are the base variables - theme palette, images/icons base paths, layout vars, etc.

// background colors
$theme-bg-color-1: #a5dd00;
$theme-bg-color-1-active: #86ba00;
$theme-bg-color-1-inactive: lighten($theme-bg-color-1, 30%);

$theme-bg-color-9: #f3dce2;
$theme-bg-color-9-active: #fac9d1;
$theme-bg-color-9-inactive: #f3dce2;

// text colors
$theme-text-color-1: #000;
$theme-text-color-1-active: #000;
$theme-text-color-1-inactive: $theme-bg-color-1;

// icons colors
$icons-color-8: 'a5dd00';