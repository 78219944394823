.cashout-item-wrapper {
    border-bottom: 1px solid $theme-bg-color-4;
    padding-bottom: 10px;
    margin-bottom: 10px;

    .cashout-item-container {
        display: flex;
        flex-direction: column;
    }

    .cashout-item-event-details {
        display: flex;
        align-items: center;
        padding: 0 8px 0 10px;
        margin: 0;
        height: 32px;
        color: $theme-text-color-6;
        font-size: 14.4px;
        font-weight: 700;
        background-color: $theme-bg-color-6;
    }

    .cashout-item {
        display: grid;
        grid-template: "market market"
                    "liability button";
        gap: 10px;
    }

    .cashout-item-button {
        grid-area: button;
        background-color: $theme-bg-color-1;
        color: $theme-text-color-1;
        padding: 5px 40px;
        cursor: pointer;
        align-self: center;
        max-width: 300px;
        border-radius: 8px;
        text-align: center;
        margin-right: 10px;

        &.negative {
            background-color: $theme-bg-color-12;
            color: $theme-text-color-12;
        }

        &.positive {
            background-color: $theme-bg-color-11;
            color: $theme-text-color-11;
        }

        .cashout-item-title{
            font-weight: bold;
        }

        .cashout-item-offer {
            font-size: 1rem;
            font-weight: bold;
        }

        .cashout-item-offer-title {
            font-weight: bold;
        }
    }

    .cashout-item-confirm-wrapper {
        display: grid;
        grid-template: "market market market"
                    "liability confirm cancel";
        gap: 10px;

        .cashout-item-confirm-button {
            padding: 10px;
            background-color: $theme-bg-color-11;
            color: $theme-text-color-11;
            cursor: pointer;
            text-align: center;
            border-radius: 6px;
            .cashout-confirm-button-title {
                font-size: 1rem;
                font-weight: bold;
            }

            .cashout-confirm-button-offer-title {
                font-weight: bold;
            }
        }

        .cashout-item-cancel-button {
            padding: 10px;
            font-size: 1.2rem;
            background-color: $theme-bg-color-12;
            color: $theme-text-color-12;
            cursor: pointer;
            margin-right: 10px;
            text-align: center;
            border-radius: 6px;
            display: grid;
            justify-content: center;
            align-content: center;
        }
    }

    .cashout-item-info {
        display: contents;

        .cashout-item-info-market {
            grid-area: market;
            background-color: $theme-bg-color-2;
            color: $theme-text-color-2;
            padding: 2px 10px;
            text-align: left;
            border-top: 1px solid lighten($theme-bg-color-2, 20%);
            border-bottom: 1px solid #CCC;
            font-weight: bold;
            display: flex;
            justify-content: space-between;
        }

        .cashout-item-info-liability {
            grid-area: liability;
            align-content: center;
            align-self: center;
            justify-self: end;
            .cashout-item-info-liability-name {
                font-weight: bold;
            }
        }
    }

    .cashout-item-in-progress {
        padding-top: 10px;
    }
}

.cashout-confirm-popup-process-overlay {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #FFF;
    z-index: 1;
    flex-direction: column;
    padding: 20px;
    border-radius: 20px;
}

/*
*
* CASHOUT CONFIRM POPUP
*
*/

.cashout-confirm-popup-wrapper {
    min-width: 270px;

    .cashout-confirm-popup-title {
        padding: 20px;
        font-size: 1.1rem;
        background-color: $theme-bg-color-4;
    }

    .cashout-confirm-popup-bet-details-container{
        &.bet-type-BACK {
            background-color: $theme-bg-color-8-inactive;
            color: $theme-text-color-8-inactive;
        }
        &.bet-type-LAY {
            background-color: $theme-bg-color-9-inactive;
            color: $theme-text-color-9-inactive;
        }
        padding: 20px;

        .cashout-confirm-popup-bet-info {
            padding-bottom: 20px;
            margin-bottom: 20px;
            border-bottom: 1px solid $theme-bg-color-3;
        }

        .cashout-confirm-popup-bet-details {
            display: flex;
            justify-content: space-evenly;

            .cashout-confirm-popup-bet-qtty {
                border-left: 1px solid $theme-bg-color-3;
                border-right: 1px solid $theme-bg-color-3;
                margin: 0 20px;
                padding: 0 20px;
            }   

            .cashout-confirm-popup-bet-qtty,
            .cashout-confirm-popup-bet-profit,
            .cashout-confirm-popup-bet-liability {
                text-align: center;
            }

            .qbcp-qtty {
                font-size: 1.2rem;
                text-align: center;
            }
        }

        .diclaimer {
            max-width: 66vw;
        }
    }

    .cashout-confirm-popup-buttons-container {
        margin-top: 20px;
        display: flex;
        gap: 20px;
        align-items: stretch;

        .cashout-confirm-popup-cancel-btn {
            height: 40px;
            width: 100%;
            text-align: center;
            background-color: $theme-bg-color-12;
            color: $theme-text-color-12;
            justify-content: center;
            display: flex;
            align-items: center;
        }

        .cashout-confirm-popup-confirm-btn {
            height: 40px;
            width: 100%;
            text-align: center;
            background-color: $theme-bg-color-1;
            color: $theme-text-color-1;
            justify-content: center;
            display: flex;
            align-items: center;
        }
    }
}