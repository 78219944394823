.empty-betslip-message{
  padding: 10px;
  color: $color__betslip_empty_message;
}

.betSlipContainer,
.openBetsContainer {
  background-color: #fff;
}

.betSlipContainer .betSlipContainer-title,
.openBetsContainer .betSlipContainer-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 46px;
  background-color: #fff;
}
.betSlipContainer .betSlipContainer-title .betSlipContainer-title-text,
.openBetsContainer .betSlipContainer-title .betSlipContainer-title-text {
  padding-left: 28px;
  font-weight: 700;
  font-size: 17px;
  color: #000;
}
.betSlipContainer .betSlipContainer-title .betSlipContainer-title-refresh,
.openBetsContainer .betSlipContainer-title .betSlipContainer-title-refresh {
  padding-right: 15px;
}
.betSlipContainer .betSlipContainer-title .betSlipContainer-title-refresh button,
.openBetsContainer .betSlipContainer-title .betSlipContainer-title-refresh button {
  height: 31px;
  padding: 0 15px;
  font-weight: 700;
  font-size: 15px;
  color: #fff;
  background-color: #000;
  border-radius: 13px;
}
.betSlipContainer .betSlipContainer-title-dark,
.openBetsContainer .betSlipContainer-title-dark {
  height: 26px;
  display: flex;
  align-items: center;
  padding-left: 15px;
  padding-right: 25px;
  font-weight: 700;
  font-size: 12px;
  color: #fff;
  background-color: #000;
  justify-content: space-between;
}
.betSlipContainer-title-arrow{
  cursor: pointer;
}
.betSlipContainer .betSlipContainer-betsWrapper .betSlipContainer-betsHeader {
  height: 25px;
  display: grid;
  align-items: center;
  font-size: 10px;
  font-family: $font-family-2;
  grid-template-columns: 1fr 60px 75px 100px;
}
.openBetsContainer .betSlipContainer-betsWrapper .betSlipContainer-betsHeader{
  height: 25px;
  display: grid;
  align-items: center;
  font-size: 14px;
  font-family: $font-family-2;
  grid-template-columns: 1fr 60px 50px 60px;
}
.betSlipContainer
  .betSlipContainer-betsWrapper
  .betSlipContainer-betsHeader:first-child {
  padding-left: 28px;
}
.openBetsContainer
  .betSlipContainer-betsWrapper
  .betSlipContainer-betsHeader:first-child {
  padding-left: 20px;
}
.betSlipContainer .betSlipContainer-line {
  display: grid;
  align-items: center;
  padding-left: 17px;
  padding-bottom: 10px;
  grid-template-columns: 1fr 60px 75px 100px;
  border-bottom: 1px solid rgba(255,255,255,0.5);
}
.openBetsContainer .betSlipContainer-line {
  display: grid;
  align-items: center;
  padding-left: 17px;
  padding-bottom: 10px;
  grid-template-columns: 1fr 60px 50px 60px;
  font-size: 10px;
}

.openBetsContainer .betSlipContainer-line.unmatched-line,
.openBetsContainer .betSlipContainer-betsHeader.unmatched-line{
  grid-template-columns: 1fr 60px 70px 70px;
}

.betSlipContainer .betSlipContainer-line:first-child {
  padding-top: 5px;
}
.betSlipContainer .betSlipContainer-line_name {
  display: grid;
  grid-template-columns: 20px 1fr;
  align-items: center;
}
.openBetsContainer .betSlipContainer-line_name {
  display: grid;
  grid-template-columns: 2fr 1fr;
  column-gap: 5px;
  align-items: center;
}
.betSlipContainer .betSlipContainer-betsHeader .betSlipContainer-line_name {
  display: block;
  padding-left: 14px;
  white-space: nowrap;
}
.openBetsContainer .betSlipContainer-betsHeader .betSlipContainer-line_name {
  display: block;
  padding-left: 2px;
}
.betSlipContainer .betSlipContainer-line_name > div {
  padding-left: 5px;
  font-size: 12px;
  font-weight: 700;
}
.betSlipContainer .betSlipContainer-actionField {
  box-sizing: border-box;
  padding: 0 5px;
  overflow: hidden;
  white-space: nowrap;
}
.betSlipContainer .betSlipContainer-actionField input {
  border: 1px solid #dcdcdc;
  width: 100%;
  text-align: center;
  font-weight: 700;
  font-size: 10px;
}
.betSlipContainer .betSlipContainer-return {
  text-align: center;
  font-size: 10px;
  font-weight: 700;
}
.betSlipContainer .betSlipContainer-line_removeBtn {
  width: 17px;
  height: 17px;
  font-size: 0;
  background: transparent $icon-betslip-remove-path no-repeat
    center center;
}
.betSlipContainer-betsWrapper.back .betSlipContainer-betsHeader {
  background-color: #a6d8ff;
}
.betSlipContainer-betsWrapper.back .betSlipContainer-betLines {
  background-color: #d2ebff;
}
.betSlipContainer-betsWrapper.lay .betSlipContainer-betsHeader {
  background-color: #fac9d1;
}
.betSlipContainer-betsWrapper.lay .betSlipContainer-betLines {
  background-color: #f3dce2;
}

// == Average odds ==
.betSlipContainer-options-wrapper {
  &.back {
    background-color: #d2ebffBB;
  }

  &.lay {
    background-color: #f3dce2BB;
  }

  padding: 6px 17px;
  font-size: 0.75rem;

  .betslipContainer-avrgOdds {
    vertical-align: middle;

    input {
      vertical-align: middle;
    }

    label {
      vertical-align: middle;
      padding-left: 5px;
    }
  }
}
// ===================

.betSlipContainer-summary {
  padding: 18px;
  background-color: #FFF;
  border-top: 2px solid $background-color__mainContent;
}
.betSlipContainer-summary-line.summary-risk {
  width: 100%;
  text-align: right;
  font-size: 10px;
}
.betSlipContainer-summary-line.summary-actionBtns {
  padding: 8px 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.betSlipContainer-summary-line.summary-actionBtns .summary-actionBtns-cancel,
.betSlipContainer-summary-line.summary-actionBtns .summary-actionBtns-placeBet {
  height: 28px;
  padding: 0 22px;
  font-size: 12px;
  font-weight: 700;
  border-radius: 4px;
}
.betSlipContainer-summary-line.summary-actionBtns .summary-actionBtns-cancel {
  color: #FFF;
  background-color: #222;
  font-size: 10px;
}
.betSlipContainer-summary-line.summary-actionBtns .summary-actionBtns-placeBet {
  color: $theme-text-color-1;
  background-color: $theme-bg-color-1;
}
.betSlipContainer-summary-line.summary-actionBtns .summary-actionBtns-placeBet:hover{
  color: $theme-text-color-1-active;
  background-color: $theme-bg-color-1-active;
}
.betSlipContainer-summary-line.summary-actionBtns .summary-actionBtns-placeBet:disabled{
  color: $theme-text-color-1-inactive;
  background-color: $theme-bg-color-1-inactive;
}

.betSlipContainer-summary-line.summary-actionBtns.seamless-placeBetBtn-wrap {
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid rgba(255,255,255,0.5);
  display: flex;
  justify-content: center;
}
.betSlipContainer-summary-line.summary-actionBtns .summary-actionBtns-placeBet.seamless-placeBet{
  width: 75%;
}

.betSlipContainer input[type=number]::-webkit-inner-spin-button, 
.betSlipContainer input[type=number]::-webkit-outer-spin-button {
   opacity: 1;
}

.betSlipContainer-openBetInfo{
  font-size: 0.7rem;
  grid-column-start: 1;
  grid-column-end: 999;
  padding: 6px 0 0;
}

.betSlipContainer-persistenceWrapper{
  grid-column-start: 1;
  grid-column-end: 999;
  padding: 6px 0 0;
}

.betSlipContainer-persistenceWrapper input {
  margin: 2px 7px;
  vertical-align: text-bottom;
}

.openBetsContainer .summaryWrapper{
  padding: 18px;
  background-color: #efefef;
}

.betSlipContainer-openBetsWrapper{
  position: relative;
}

.openBetsModResultMessagesWrapper{
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.65);
  z-index: 2;

  .openBetsModResultMessagesContainer {
    position: sticky;
    top: 0;
  }
}

.openBetsisLoadingMessagesWrapper{
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.65);
  
  .loading-spinner-container {
    top: 50%;
    transform: translate(-20px, -20px);
    position: absolute;
    left: 50%;
  }
}

.opmrButtonContainer{
  margin: 0 auto;
  text-align: center;
  background-color: #a6d8ff;
  padding: 10px 0;
}

.opmrButtonContainer button{
  background-color: #CCC;
  padding: 8px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.opmrContainer{
  background-color: #a6d8ff;
}

.opmrContainer .opmrMessage{
  background-color: #a6d8ff;
  border-bottom: 1px solid #FFF;
  padding: 10px;
}

.clear-order-button{
  background-color: red;
  color: #FFF;
  padding: 0 4px;
  margin-right: 10px;
  cursor: pointer;
  font-size: 0.6rem;
  vertical-align: text-top;
}

.betSlipContainer-oddsMessage{
  background-color: coral;
  color: #FFF;
  padding: 5px 10px;
}

.myMarketsContainer{
  background-color: $theme-bg-color-3;
  padding-top: 1px;

  .my-markets-market-container{
    margin-bottom: 1px;

    .my-markets-market-host-container{
      background-color: $theme-bg-color-1;
      padding: 6px 10px;
      color: $theme-text-color-1;
      display: flex;
      font-size: 12px;

      .my-markets-market-sport-icon-container{
        display: inline-block;
        width: 20px;
        height: 20px;
        margin-right: 10px;
        vertical-align: middle;

        .sport-icon{
          display: block;
          width: 20px;
          height: 20px;
          background-repeat: no-repeat;
          background-position: center;
        }
      }

      .my-markets-market-category{
        font-weight: bold;
        flex-grow: 1;
      }

      .my-markets-market-tournament{
        align-self: flex-end;
      }
    }

    .my-markets-market-data-container{
      padding: 10px 10px;
      font-size: 10px;
      background-color: $theme-bg-color-13
    }
  }
}

.content-right-mob-title-container{
  background-color: $theme-bg-color-5;
  color: $theme-text-color-5;
  padding: 10px;
  font-size: 1.1rem;
  border-bottom: 1px solid $theme-bg-color-4;
  border-top: 2px solid $theme-bg-color-1;
  position: relative;
  line-height: 30px;

  .content-right-mob-title-close {
    position: absolute;
    width: 30px;
    height: 30px;
    right: 10px;
    top: 10px;
    background-color: $theme-bg-color-1;
    border-radius: 3px;
    background-image: $icon-virtual-kbd-remove-path;
    background-position: center;
    background-repeat: no-repeat;
  }
}

.open-bet-update-mobile-edit-btn-wrapper {
  grid-column-start: 1;
  grid-column-end: -1;
  justify-self: end;
  .open-bet-update-mobile-edit-btn-container {
    padding: 10px 20px;
    background-color: $theme-bg-color-1;
    color: $theme-text-color-1;
    margin: 10px;
    border-radius: 5px;
  }
}

.open-bets-bet-updates-wrapper-mobile {
  grid-column-start: 1;
  grid-column-end: -1;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  margin-right: 17px;
  border-top: 1px solid #FFF;
  margin-top: 10px;
  padding-top: 10px;


  .ui-el-bet-odds-values-item--wrapper{
    .ui-el-bet-odds-values-item{
        height: 30px;
        line-height: 30px;
        font-size: 1.1rem;
        padding: 0 30px;
        text-align: center;
        width: 100%;
    }

    .ui-el-bet-odds-values-item--arrows-container{
        position: static;
        width: 0;
        height: 0;

        .ui-el-bet-odds-values-item--arrow{
            position: absolute;
            width: 30px;
            height: 30px;
            top: 0;
            background-color: $theme-bg-color-1;
            background-repeat: no-repeat;
            background-position: center;
            background-size: 40% auto;

            &.ui-el-bet-odds-values-item--arrow-down{
                background-image: $icon-arrows-down-full-path;
                left: 0;
            }

            &.ui-el-bet-odds-values-item--arrow-up{
                background-image: $icon-arrows-up-full-path;
                right: 0;
            }
        }
    }

}

.ui-el-bet-stake-values-item--wrapper{
    position: relative;

    .ui-el-bet-stake-values-item{
        height: 30px;
        line-height: 30px;
        font-size: 1.1rem;
        padding: 0 30px;
        text-align: center;
        width: 100%;
    }

    .ui-el-bet-stake-values-item--arrows-container{
        position: static;
        width: 0;
        height: 0;

        .ui-el-bet-stake-values-item--arrow{
            position: absolute;
            width: 30px;
            height: 30px;
            top: 0;
            background-color: $theme-bg-color-1;
            background-repeat: no-repeat;
            background-position: center;
            background-size: 40% auto;

            &.ui-el-bet-stake-values-item--arrow-down{
                left: 0;
                background-image: $icon-arrows-down-full-path;
            }

            &.ui-el-bet-stake-values-item--arrow-up{
                right: 0;
                background-image: $icon-arrows-up-full-path;
            }
        }
    }

  }

  .betSlipContainer-persistenceWrapper {
    grid-column-start: 1;
    grid-column-end: -1;
  }

  input[type=checkbox] {
    width: 30px;
    height: 30px;
    vertical-align: middle;
  }

  .open-bet-update-mobile-update-btn-container {
    padding: 10px 20px;
    background-color: $theme-bg-color-1;
    color: $theme-text-color-1;
    margin: 10px;
    border-radius: 5px;
    grid-column: 2;
  }

  .open-bet-update-mobile-cancel-btn-container {
    padding: 10px 20px;
    background-color: $theme-bg-color-12;
    color: $theme-text-color-12;
    margin: 10px;
    border-radius: 5px;
    grid-column: 1;
  }
}

@media screen and (max-width: $screen-width-tablet-max-width) {
  .content-main .content-right {
    width: auto;
    position: fixed;
    bottom: 0;
    top: unset;
    background-color: #dfe5e5;
    border: 1px solid #000;
    transition: transform 0.25s ease-in-out, -webkit-transform 0.25s ease-in-out;
    z-index: 3;
    overflow-y: auto;
    min-height: 220px;
    max-height: 75%;
    width: 100%;
    max-width: 600px;
    right: 50%;

    &.content-right-seamless {
      position: absolute;
    }
  }
  .content-right {
    right: 0;
    -webkit-transform: translate3d(50%, 100%, 0);
    transform: translate3d(50%, 100%, 0);

    &.content-right-seamless {
      -webkit-transform: translate3d(50%, 100%, 0);
      transform: translate3d(50%, 200%, 0);
    }
  }
  .content-right.rightPanelToggle {
    -webkit-transform: translate3d(50%, 0, 0);
    transform: translate3d(50%, 0, 0);
  }
}

@media screen and (max-width: $screen-width-mobile-max-width) {
  .content-main .content-right {
    border:none;
    border-top: 1px solid #000;
  }
}